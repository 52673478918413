<template>
	<v-card class="shadow-blue pa30 mt-5">
		<h2>Points gagnés sur le programme</h2>
		<span class="ortho-text-gray">Les points gagnés sur les notions vues durant le programme</span>
		<p class="fs30 font-weight-bold mb-3 mt-3 text-right" style="color: #34e5c8">
			{{ score }} / {{ totalScore }} <img class="iconsBigRules" src="@/assets/icons/lightening-green.svg" />
		</p>

		<v-progress-linear
			:value="(score / totalScore) * 100"
			color="#34e5c8"
			height="10"
			rounded
			background-color="#F1F1F1"
		></v-progress-linear>
	</v-card>
</template>
<script>
export default {
	name: 'TotalPointCard',
	components: {},
	props: {
		totalScore: Number,
		score: Number,
	},
	computed: {},
	methods: {},
};
</script>
<style lang="scss" scoped>
.iconsBigRules {
	width: 23px;
}
</style>
