<template>
	<v-card class="notion-resume-card shadow-blue pa30 mt-5" style="position: relative">
		<h2 class="mb-6">
			{{ title }}
		</h2>

		<div
			v-for="(notion, index) in sortPositionAsc(sessions)"
			:key="index"
			class="notion-title-container border-section-completed pt-4 pb-4 d-flex justify-space-between align-center"
		>
			<div class="ruleInfo">
				<h4>{{ notion.title }}</h4>
			</div>

			<div class="score-block d-flex align-center">
				<span class="scoreInfoMaster mr-1" :style="{ color: getIconColor(notion.score) }"> {{ notion.score }}% </span>
				<v-icon class="mr-1" :color="getIconColor(notion.score)">{{ getIconText(notion.score) }}</v-icon>
			</div>
		</div>
	</v-card>
</template>
<script>
export default {
	name: 'NotionResumeCard',
	components: {},
	props: { sessions: Array },
	computed: {
		title() {
			const sessionHasScoreGreaterThan80 = this.sessions.filter((s) => s.score >= 80);
			return `${sessionHasScoreGreaterThan80.length}/${this.sessions.length} notions maîtrisées`;
		},
		sessionTotal() {
			return this.sessions.length;
		},
	},
	methods: {
		getIconColor(score) {
			return score > 80 ? '#34e5c8' : '#a6a6a6';
		},
		getIconText(score) {
			return score > 80 ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle-outline';
		},
		sortPositionAsc(session) {
			return [...session].sort((a, b) => a.position - b.position);
		},
	},
};
</script>
<style lang="scss" scoped>
.notion-resume-card {
	.notion-title-container {
		display: flex;
		gap: 24px;
	}
	.score-block {
		color: #34e5c8;
		font-weight: bold;
	}
}
</style>
