<template>
	<v-container fluid>
		<Loading :show="loading" label="3, 2, 1..." />

		<div v-if="formationProgress && category && !error">
			<div class="background" :class="{ 'step-1-bg-color': step === 1, 'step-2-3-bg-color': step !== 1 }"></div>

			<!-- Button goto next step -->
			<v-btn
				dark
				large
				color="black"
				@click="toStep2()"
				v-if="
					step == 1 &&
					formationProgress.formation.last_category == null &&
					formationProgress.formation.formation_finished != true
				"
				class="button-continue hvr-grow"
				>Continuer</v-btn
			>
			<!-- END Button goto next step -->

			<v-col class="pt-0 pb-0" :class="{ 'pt-12': formationProgress.formation.last_category != null }">
				<v-stepper v-model="step">
					<v-col class="pt-0 pb-0">
						<!-- Header Stepper -->
						<v-col
							offset-md="3"
							xl="6"
							md="6"
							sm="12"
							cols="12"
							class="pt-0"
							v-if="
								formationProgress.formation.last_category == null &&
								formationProgress.formation.formation_finished !== true
							"
						>
							<v-stepper-header>
								<v-stepper-step :complete="step > 1" step="1">Rapport</v-stepper-step>
								<v-divider></v-divider>
								<v-stepper-step :complete="step > 2" step="2">Nouveau programme</v-stepper-step>
								<v-divider></v-divider>
								<v-stepper-step step="3">Confirmation</v-stepper-step>
							</v-stepper-header>
						</v-col>
						<!-- END Header Stepper -->
					</v-col>

					<Congrats :profileInfos="profileInfos" :categoryName="category.name" :step="step" />
					<Badges v-if="step === 1" class="pb-3 pt-3" />

					<v-stepper-items>
						<!-- Step 1 -->
						<v-stepper-content step="1" class="pa-0">
							<div
								class="div-step mb-6 pl-2 pr-2 pl-md-0 pr-md-0"
								:class="{ 'mb-12': formationProgress.formation.last_category != null }"
							>
								<SessionPerformance
									class="mt-8"
									:totalScoreInPercent="categoryScoreAverage"
									title="Performance globale"
									subTitlePassed="C'est ta moyenne de bonnes réponses sur le programme"
									subTitleNotPassed="C'est ta moyenne de bonnes réponses sur le programme"
								/>
								<TotalPointCard :totalScore="categoryTotalPoints" :score="categoryPoints" />
								<NotionResumeCard :sessions="category.sessions" />
							</div>
						</v-stepper-content>
						<!-- END Step 1 -->

						<!-- Step 2 -->
						<v-stepper-content
							step="2"
							v-if="formationProgress.formation.last_category == null"
							:class="{ 'mt-10': $vuetify.breakpoint.mdAndUp }"
						>
							<div class="div-step mt-10 mb-6" :class="{ 'mb-12': formationProgress.formation.last_category != null }">
								<component
									:is="Step2Component"
									:formationId="formationProgress.formation._id"
									:listCategories="filterLockedCategory(parcours.categories)"
									@categorySelected="onCategorySelected"
									@selectionSucceed="onSelectionSucceed"
								/>
							</div>
						</v-stepper-content>
						<!-- END Step 2 -->

						<!-- Step 3 -->
						<v-stepper-content step="3" class="pb-0 pt-0" v-if="formationProgress.formation.last_category == null">
							<div class="div-step mb-6" :class="{ 'mb-12': formationProgress.formation.last_category != null }">
								<Step3Component :categorySelected="newCategorySelected" v-if="step === 3" />
							</div>
						</v-stepper-content>
						<!-- END Step 3 -->
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from 'vue-full-loading';

import Congrats from '@/components/reports/category/Congrats.vue';
import Badges from '@/components/reports/category/Badges.vue';
import SessionPerformance from '@/components/session-result/SessionPerformance.vue';
import TotalPointCard from '@/components/reports/category/redaction/TotalPointCard.vue';
import NotionResumeCard from '@/components/reports/category/redaction/NotionResumeCard.vue';
import Step3Component from '@/components/reports/category/redaction/ChooseChapterStep3.vue';
import { CategoryStatus } from '@/constants/category.js';

export default {
	name: 'ReportChapterCategory',
	components: { Loading, Congrats, Badges, SessionPerformance, TotalPointCard, NotionResumeCard, Step3Component },
	data() {
		return {
			loading: false,
			step: 1,
			Step2Component: null,
			error: false,
			profileInfos: {},
			category: null,
			parcours: null,
			newCategorySelected: null,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		categoryScoreAverage() {
			if (!this.category) return 0;

			let totalScore = 0;
			let sessionCount = 0;

			this.category.sessions.forEach((session) => {
				if (session.score !== undefined) {
					totalScore += session.score;
					sessionCount++;
				}
			});

			if (sessionCount === 0) return 0;

			const averageScore = totalScore / sessionCount;
			return averageScore;
		},
		categoryTotalPoints() {
			if (!this.category) return 0;

			return this.category.sessions.length * 1000;
		},
		categoryPoints() {
			if (!this.category) return 0;

			let totalScore = 0;

			this.category.sessions.forEach((session) => {
				if (session.score != null) {
					totalScore += session.score * 10;
				}
			});

			return totalScore;
		},
	},
	methods: {
		getParcoursAndCategory(categoryId) {
			this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
				const foundCategory = parcoursItem.parcours_id.categories.find((category) => category._id === categoryId);
				if (foundCategory) {
					this.parcours = parcoursItem.parcours_id;
					this.category = foundCategory;
				}
			});
		},
		async toStep2() {
			this.step = 2;
			this.Step2Component = async () =>
				await import('../../components/reports/category/redaction/ChooseChapterStep2.vue');
		},
		filterLockedCategory(categoriesWithStatusArr) {
			return categoriesWithStatusArr.filter((cat) => cat.status === CategoryStatus.LOCKED);
		},
		onSelectionSucceed(value) {
			if (value == true) this.step = 3;
		},
		onCategorySelected(value) {
			this.newCategorySelected = value;
		},
	},
	async mounted() {
		const { formationId, categoryId } = this.$route.params;
		const { step } = this.$route.query;
		this.loading = true;

		await this.$store.dispatch('profile/formationProgress', { idFormation: formationId });
		this.profileInfos = { first_name: this.formationProgress.user.first_name };
		this.getParcoursAndCategory(categoryId);

		if (Number(step) === 2) this.toStep2();

		this.loading = false;
	},
};
</script>

<style lang="scss" scoped>
.div-step {
	max-width: 800px;
	margin: 0 auto;
}
.background {
	position: absolute;
	left: 0px;
	height: 50vh;
	width: 100%;
	top: 0;
}

.step-1-bg-color {
	background-color: #25b29b;
}
.step-2-3-bg-color {
	background-color: #120136;
}

::v-deep {
	.theme--light.v-stepper {
		background: transparent;
		box-shadow: none;
		.v-divider {
			border-color: white;
			margin: 0px;
		}
		.v-stepper__header {
			box-shadow: none;
			.v-stepper__label {
				color: white;
			}
			.v-stepper__step__step {
				background-color: white !important;
				border-color: white !important;
				color: #25b29b;
			}
		}
	}
	.theme--light.v-stepper .v-stepper__step__step .v-icon {
		color: #25b29b;
	}
}
.button-continue {
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	width: 350px;
}
@media only screen and (max-width: 600px) {
	.div-step {
		width: 100%;
		margin: 0 auto;
	}
	.button-continue {
		width: 250px;
	}
}
</style>
